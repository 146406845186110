































































































































@import '@/assets/scss/variables/_variables';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.wh-form-range {
  > div {
    height: auto;
  }
}
